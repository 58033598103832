import React from "react";
import { Link, graphql } from "gatsby";
import Helmet from "react-helmet";
import CTA from "../components/cta";
import Layout from "../components/layout";
import "./prismic-policy-page.scss";

export const query = graphql`
  query($slug: String!) {
    prismicPolicyPage(uid: { eq: $slug }) {
      uid
      data {
        title {
          html
          text
        }
        content {
          html
          text
        }
      }
    }
    allPrismicPolicyPage {
      edges {
        node {
          uid
          data {
            listed
            title {
              text
            }
          }
        }
      }
    }
  }
`;

const PolicyPage = ({ data }) => (
  <Layout>
    <Helmet
      title={`ProCliq | ${data.prismicPolicyPage.data.title.text}`}
      meta={[
        {
          property: "og:title",
          content: data.prismicPolicyPage.data.title.text,
        },
        {
          name: "twitter:title",
          content: data.prismicPolicyPage.data.title.text,
        },
      ]}
    />
    <div className="policyPage">
      <div className="headerBackdrop" />
      <div className="type">Legal</div>
      <div className="content">
        <div className="sidebar">
          {data.allPrismicPolicyPage.edges
            .sort((a, b) => {
              if (a.node.data.title.text < b.node.data.title.text) return -1;
              return 1;
            })
            .filter(({ node }) => node.data.listed !== "false")
            .map(({ node }) => (
              <Link
                key={node.uid}
                className={
                  data.prismicPolicyPage.uid === node.uid ? "selected" : ""
                }
                to={`/legal/${node.uid}/`}
              >
                {node.data.title.text}
              </Link>
            ))}
        </div>
        <div className="policyContent">
          <div className="title">{data.prismicPolicyPage.data.title.text}</div>
          <div
            className="body"
            dangerouslySetInnerHTML={{
              __html: data.prismicPolicyPage.data.content.html,
            }}
          />
        </div>
      </div>
    </div>
    <CTA />
  </Layout>
);

export default PolicyPage;
